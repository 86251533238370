import { Drawer, Typography, Avatar, Tag, Empty } from "antd";
import moment from "moment";
import { getRegionInformation } from "enums";
const { Text } = Typography;
const ViewLeadInformation = ({ data, onClose = () => {} }) => {
  const {
    name,
    phone,
    email,
    country,
    designation,
    company,
    lead_source,
    interested_in,
    assigned_on,
    created,
    assignedUser,
  } = data;
  return (
    <>
      <Drawer
        title="Lead Information"
        width={720}
        onClose={() => onClose(false)}
        open={data ? true : false}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <table className="view-lead-table">
          <tr>
            <td className="heading-column">Name</td>
            <td>{name}</td>
            <td className="heading-column">Phone</td>
            <td>{phone}</td>
          </tr>
          <tr>
            <td className="heading-column">Email</td>
            <td>{email}</td>
            <td className="heading-column">Country</td>
            <td>{country ? country : "NA"}</td>
          </tr>
          <tr>
            <td className="heading-column">Company</td>
            <td>{company}</td>
            <td className="heading-column">Designation</td>
            <td>{designation}</td>
          </tr>
          <tr>
            <td className="heading-column">Lead Source</td>
            <td>{lead_source || "NA"}</td>
            <td className="heading-column">Campaign Name</td>
            <td>{interested_in ? interested_in : "NA"}</td>
          </tr>
          <tr>
            <td className="heading-column">Created On</td>
            <td>{moment(created).format("dddd") + ' ' +moment(created).format("MMM Do YYYY") || "NA"}</td>
            <td className="heading-column">Assigned On</td>
            <td>{moment(assigned_on).format("dddd") + ' '+ moment(assigned_on).format("MMM Do YYYY") || "NA"}</td>
          </tr>
        </table>
        <div className="mt-2">
          <Text strong>Assigned Relationship Manager</Text>
          {assignedUser ? (
            <div className="profile-box">
              {assignedUser?.profile_image === "_defaultUser.png" ? (
                <Avatar
                  size={50}
                  style={{
                    backgroundColor: "#f56a00",
                  }}
                >
                  {assignedUser?.f_name.charAt(0) || "NA"}
                </Avatar>
              ) : (
                <Avatar size={50} src={assignedUser?.profile_image} />
              )}
              <div className="profile-detail-information">
                <Text>{assignedUser?.f_name + " " + assignedUser?.l_name}</Text>
                <Text>{assignedUser?.email}</Text>
              </div>
              <div>
                <Tag
                  color={getRegionInformation(assignedUser?.region || 0)?.color}
                >
                  {getRegionInformation(assignedUser?.region || 0)?.title}
                </Tag>
              </div>
            </div>
          ) : (
            <Empty
              description={<span>Not Assgined to any user</span>}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
      </Drawer>
    </>
  );
};
export default ViewLeadInformation;
