/**
 * useNavigateInApp
 */
import { useNavigate } from "react-router-dom";

const useNavigateInApp = () => {
  const navigate = useNavigate();
  return navigate;
};

export default useNavigateInApp;
