import { Table, Typography } from "antd";
import moment from "moment";
const { Text } = Typography;
const CertifiedUserView = ({ data }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "sr",
      key: "sr",
      render: (_, record, index) => (
        <>
          <Text>{index + 1}</Text>
        </>
      ),
    },
    {
      title: "Certificate No",
      dataIndex: "certificate_no",
      key: "certificate_no",
      render: (_, record) => (
        <>
          <Text>{record.certificate_no}</Text>
        </>
      ),
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
      render: (_, record) => (
        <>
          <Text>{moment(record.issue_date).format("MMMM Do YYYY")}</Text>
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data.certificates}
        pagination={false}
      />
    </>
  );
};

export default CertifiedUserView;
