import { useState } from "react";
import { getDashboardGraph } from "services";
import { App } from "antd";
// import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
const useAdminLineChartGraph = () => {
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({
    endDate: searchParams.get("startDate") || moment().format("YYYY-MM-DD"),
    startDate:
      searchParams.get("endDate") ||
      moment().subtract(7, "days").format("YYYY-MM-DD"),
    key: "orders",
  });

  const { isLoading, isError, data } = useQuery({
    queryKey: ["/dashboard-graph", params],
    queryFn: () => getDashboardGraph(params),
    keepPreviousData: true,
  });

  if (isError) {
    message.open({
      type: "error",
      content: "Something went wrong, please try again later!",
    });
  }

  const handleSearchParams = (startDate, endDate) => {
    searchParams.set("startDate", startDate);
    searchParams.set("endDate", endDate);
    setSearchParams(searchParams);
    setParams({
      endDate: startDate,
      startDate: endDate,
      key: "orders",
    });
  };

  const handleFilterChange = (value) => {
    switch (value) {
      case "today":
        handleSearchParams(
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD")
        );
        break;
      case "yesterday":
        handleSearchParams(
          moment().format("YYYY-MM-DD"),
          moment().subtract(1, "days").format("YYYY-MM-DD")
        );
        break;
      case "7days":
        handleSearchParams(
          moment().format("YYYY-MM-DD"),
          moment().subtract(7, "days").format("YYYY-MM-DD")
        );
        break;

      case "28days":
        handleSearchParams(
          moment().format("YYYY-MM-DD"),
          moment().subtract(28, "days").format("YYYY-MM-DD")
        );
        break;

      case "90days":
        handleSearchParams(
          moment().format("YYYY-MM-DD"),
          moment().subtract(90, "days").format("YYYY-MM-DD")
        );
        break;

      // case "custom":
      //   console.log(value);
      // default:
      //   console.log(value);
    }
  };

  return {
    isLoading,
    data: data?.data || [],
    handleFilterChange,
  };
};
export default useAdminLineChartGraph;
