import { axios } from "utils/axios";
const controller_name = "user/";

export const changeUserAvatar = (user_id, data) => {
  let result = axios
    .post(`${controller_name}updateAvatar/${user_id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const changeUserPassword = (user_id, data) => {
  let result = axios
    .post(`${controller_name}update-password/${user_id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const getAllUser = () => {
  let result = axios
    .get(`${controller_name}get`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const getUserDetails = (user_id) => {
  let result = axios
    .get(`${controller_name}get-user-details/${user_id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};
