import { useState } from "react";

const useAddPaymentLogic = () => {
  const [selectedCurrency, setSelectedCurrency] = useState("AED");
  const currencies = localStorage.getItem("currencies") || JSON.stringify('{}');
  const tabList = [
    {
      key: "payment",
      label: "Payments",
      children: "",
    },
    {
      key: "deducation",
      label: "Deductions",
      children: "",
    },
  ];
  const getCurrencyConvertedValue = (value) => {
    const parsedValue = parseFloat(value);
    const parsedCurrencies = JSON.parse(JSON.parse(currencies));
    let finalValue;
    if (selectedCurrency !== "AED") {
      finalValue = parsedValue / parsedCurrencies[selectedCurrency] || 1;
    } else {
      finalValue = parsedValue;
    }
    return finalValue
  };
  const [activeTab, setActiveTab] = useState(tabList[0].key);
  return { tabList, setActiveTab, activeTab, selectedCurrency, setSelectedCurrency, getCurrencyConvertedValue };
};
export default useAddPaymentLogic;
