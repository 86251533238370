import { useState } from "react";
import { Button, Tooltip, Avatar, Popconfirm } from "antd";
import {
  DollarOutlined,
  ContainerOutlined,
  TeamOutlined,
  AppstoreAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useNavigateInApp } from "hooks";
import useLoginViewLogic from "business-logic/login-view-logic";
import Logo from "assets/images/img_logo.png"
// import NotificationWrapper from "components/notification";
const LeftPanel = () => {
  const { pathname } = window.location;
  const { userSignOut, userInfo } = useLoginViewLogic();
  const navigate = useNavigateInApp();
  const [activeRoute, setActiveRoute] = useState(pathname);
  const handleRouteChange = (path) => {
    setActiveRoute(path);
    if (path === "/logout") {
      userSignOut();
      navigate("/");
    } else {
      navigate(path);
    }
  };
  return (
    <>
      <div className="left-sidebar">
        <div className="upper-routes">
          <div
            onClick={() => handleRouteChange("/")}
            className="logo-container cursor-pointer"
          >
            <img
              alt="IMC LOGO LEFT ACTION BAR"
              className="image-cover"
              src={Logo}
            />
          </div>
          <div className="left-routes">
            {left_routes.map((route) => (
              <Tooltip
                key={`Upper Routes ${route.title}`}
                placement="right"
                title={route.title}
              >
                <Button
                  disabled={route.disabled}
                  type={route.path === activeRoute ? "primary" : "default"}
                  onClick={() => handleRouteChange(route.path)}
                  placement="right"
                  icon={route.icon}
                />
              </Tooltip>
            ))}
          </div>
        </div>
        <div className="lower-routes">
          <div className="left-routes">
            {/* <NotificationWrapper /> */}
            <Popconfirm
              placement="right"
              title={"Are you sure you want to sign out"}
              onConfirm={() => userSignOut()}
              okText="Yes"
              cancelText="No"
            >
              <Button placement="right" icon={<LogoutOutlined />} />
            </Popconfirm>
            <div
              onClick={() => handleRouteChange("/settings")}
              className="cursor-pointer"
            >
              <Avatar
                style={{
                  backgroundColor: "#1677ff",
                  verticalAlign: "middle",
                }}
                size="medium"
              >
                {userInfo?.userData?.f_name?.charAt(0)}
              </Avatar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LeftPanel;

const left_routes = [
  {
    title: "Dashboard",
    icon: <AppstoreAddOutlined />,
    type: "upper_routes",
    disabled: false,
    path: "/",
  },
  {
    title: "Orders",
    type: "upper_routes",
    path: "/orders",
    icon: <ContainerOutlined />,
    disabled: false,
  },
  {
    title: "Payments",
    type: "upper_routes",
    path: "/payments",
    icon: <DollarOutlined />,
    disabled: true,
  },
  {
    title: "Teams",
    type: "upper_routes",
    path: "/teams",
    disabled: true,
    icon: <TeamOutlined />,
  },
];
