import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Divider,
  Tabs,
  Spin,
} from "antd";
import { payment_methods } from "enums";
import useOrdersViewLogic from "business-logic/orders-view-logic";
import PaymentView from "components/payment-view";
import useAddPaymentLogic from "business-logic/add-payment-view-logic";
import PaymentSummary from "components/payment-summary";
const { Option } = Select;
const AddPayment = ({ isOpen, onClose = () => { }, userEmail }) => {
  const [form] = Form.useForm();
  const { handleAddPaymentSubmit, loading } = useOrdersViewLogic();
  const { tabList, setActiveTab, activeTab, setSelectedCurrency, getCurrencyConvertedValue } = useAddPaymentLogic();
  const selectAfter = (
    <Select
      defaultValue="AED"
      onChange={(value) => setSelectedCurrency(value)}
      style={{
        width: 70,
      }}
    >
      <Option value="AED">AED</Option>
      <Option value="USD">USD</Option>
      <Option value="PKR">PKR</Option>
      <Option value="INR">INR</Option>
      <Option value="SAR">SAR</Option>
      <Option value="OMAN">OMAN</Option>
      <Option value="KWD">Kuwait</Option>
      <Option value="BHD">Behrain</Option>
      <Option value="PHP">Phillipe</Option>
      <Option value="EGP">Egypt</Option>
    </Select>
  );
  return (
    <>
      <Drawer
        title="Payments"
        width={720}
        onClose={() => onClose(false)}
        open={isOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Spin spinning={loading}>
          <PaymentSummary payments={isOpen?.payments || []} />
          <Divider></Divider>
          <Tabs
            defaultActiveKey="1"
            items={tabList}
            onChange={(key) => setActiveTab(key)}
          />
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={() =>
              handleAddPaymentSubmit(
                form,
                () => onClose(false),
                isOpen,
                activeTab === "payment" ? 0 : 2,
                getCurrencyConvertedValue(form.getFieldValue("payment_amount"))
              )
            }
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="payment_amount"
                  label="Paid Amount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter paid amount",
                    },
                  ]}
                >
                  <Input
                    min={1}
                    // max={isOpen.sale_amount}
                    type="number"
                    addonAfter={selectAfter}
                    placeholder="Please enter paid amount"
                  />
                </Form.Item>
              </Col>
              {activeTab === "payment" ? (
                <Col span={12}>
                  <Form.Item
                    name="payment_method"
                    label="Payment Method"
                    rules={[
                      {
                        required: true,
                        message: "Please enter payment Method",
                      },
                    ]}
                  >
                    <Select placeholder="Please select an method">
                      {payment_methods
                        .sort(function (a, b) {
                          if (a.title < b.title) {
                            return -1;
                          }
                          if (a.title > b.title) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((gateway) => (
                          <Option
                            key={`Payment Gateways ${gateway.title}`}
                            value={gateway.title}
                          >
                            {gateway.title}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="remarks"
                  label="Notes"
                  rules={[
                    {
                      required: false,
                      message: "please enter remarks",
                    },
                  ]}
                >
                  <Input.TextArea rows={2} placeholder="Please enter remarks" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
            </Form.Item>
          </Form>
          {activeTab === "payment" ? (
            <>
              <Divider>Payments</Divider>
              <PaymentView
                orderId={isOpen.id}
                lmsUserId={isOpen.lms_user_id}
                payments={isOpen.payments.filter((x) => x.payment_status !== 2)}
                mode={activeTab}
              />
            </>
          ) : (
            <>
              <Divider>Deductions</Divider>
              <PaymentView
                orderId={isOpen.id}
                lmsUserId={isOpen.lms_user_id}
                payments={isOpen.payments.filter((x) => x.payment_status === 2)}
                mode={activeTab}
              />
            </>
          )}
        </Spin>
      </Drawer>
    </>
  );
};
export default AddPayment;
