import {
  Button,
  Tooltip,
  Input,
  Table,
  Typography,
  Space,
  Tag,
  Avatar,
  Popconfirm,
} from "antd";
import {
  ReloadOutlined,
  DollarOutlined,
  ProfileOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import useOrdersViewLogic from "business-logic/orders-view-logic";
import ViewLeadInformation from "components/view-lead-information";
import AddPayment from "components/add-payment";
import OrderTimeline from "components/order-timeline";
import {
  order_user_status_enums,
  marketers,
  user_roles,
} from "enums";
import ServiceModal from "components/service-modal";
import FilterOrders from "components/filter-orders";
import UserTile from "components/user-tile";
import OrderDate from "components/order-date";
import RevenueHandler from "components/revenue-handler";
import OrderPaymentStatus from "components/order-payment-tile";
import PaymentSummary from "components/payment-summary";
import moment from "moment";
const { Search } = Input;
const { Text } = Typography;

const OrdersView = () => {
  const {
    isLoading,
    data,
    refreshData,
    viewLeadDrawer,
    setViewLeadDrawer,
    changePage,
    addPaymentDrawer,
    setAddPaymentDrawer,
    handleUpdateOrder,
    showOrderActivity,
    setShowOrderActivty,
    onSearch,
    isPaymentButtonDisabled,
    filterColumns,
    currentRole,
    handleRemoveOrder,
    handleFilter,
    userEmail,
    filterApplied,
    setFilterApplied
  } = useOrdersViewLogic(true);
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 60,
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "order_date",
      key: "order_date",
      width: 230,
      // ellipsis: true,
      render: (_, record) => (
        <>
          <OrderDate
            key={`Order Date ${record.id}`}
            onDateChange={(value) =>
              handleUpdateOrder(value, record.id, "order_date")
            }
            record={record}
          />
        </>
      ),
    },
    {
      title: "Name/Email",
      dataIndex: "name",
      width: 210,
      ellipsis: true,
      key: "name",
      render: (_, record) => (
        <>
          <Text>
            {record?.lead?.name}
            <br />
            {record?.lead?.email}
          </Text>
        </>
      ),
    },
    {
      title: "Designation/Company",
      dataIndex: "name",
      width: 250,
      ellipsis: true,
      key: "name",
      render: (_, record) => (
        <>
          <Text>
            {record?.lead?.designation}
            <br />
            {record?.lead?.company}
          </Text>
        </>
      ),
    },
    {
      title: "Revenue",
      dataIndex: "sale_amount",
      key: "sale_amount",
      width: 180,
      render: (_, record) => (
        <>
          <RevenueHandler userEmail={userEmail} record={record} onUpdate={handleUpdateOrder} />
        </>
      ),
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      width: 360,
      ellipsis: true,
      render: (_, record) => (
        <>
          <PaymentSummary size="small" payments={record?.payments || []} />
        </>
      ),
    },
    {
      title: "Balance Amount",
      dataIndex: "payment_status",
      key: "payment_status",
      width: 160,
      ellipsis: true,
      render: (_, record) => (
        <>
          <OrderPaymentStatus record={record} />
        </>
      ),
    },

    {
      title: "Relationship Manager",
      dataIndex: "sale_amount",
      key: "sale_amount",
      width: 180,
      render: (_, record) => (
        <>
          {record?.relationship_manager ? (
            <UserTile onClick={() => {
              const filter = {
                relationshipManagers: record?.sold_by,
                range: `${moment().startOf('month').format('YYYY-MM-DD')},${moment().endOf('month').format('YYYY-MM-DD')}`
              };
              window.scrollTo(0, 0);
              setFilterApplied(1);
              handleFilter(JSON.stringify(filter));
            }} userId={record?.sold_by || null} assignedUser={record?.relationship_manager || {}} />
          ) : (
            <Text>Not Assgined to any user</Text>
          )}
        </>
      ),
    },
    {
      title: "Marketer",
      dataIndex: "marketer",
      key: "marketer",
      width: 170,
      render: (_, record) => {
        const { marketer } = record?.lead;
        const selectedMarketer = marketers[marketer];
        return (
          <>
            <div className="profile-box">
              <Avatar size={40} src={selectedMarketer?.image} />
              <div className="profile-detail-information">
                <Text>{selectedMarketer?.title || "NA"}</Text>
                {/* {selectedMarketer?.designation ? ( */}
                <Tag style={{ maxWidth: "fit-content" }} color="geekblue">
                  {selectedMarketer?.designation || "NA"}
                </Tag>
                {/* ) : null} */}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: 180,
      fixed: 'right',
      ellipsis: true,
      render: (_, record) => (
        <Space>
          {/* <Tooltip placement="bottom" title="View Lead">
            <Button
              onClick={() => setViewLeadDrawer(record.lead)}
              size="small"
              icon={<EyeOutlined />}
            />
          </Tooltip> */}
          {user_roles[currentRole].showServiceOption ? (
            <ServiceModal
              disabled={
                !(
                  order_user_status_enums[record.login_generated]?.title ===
                  "Enrolled"
                )
              }
              user_email={record?.lead?.email}
              order_id={record?.id}
              user_id={record?.lms_user_id}
            />
          ) : null}
          {user_roles[currentRole].showAccountsOption ? (
            <Tooltip placement="bottom" title="Payments">
              <Button
                disabled={isPaymentButtonDisabled(
                  record.sale_amount,
                  record.payments
                )}
                onClick={() =>
                  setAddPaymentDrawer({
                    order_id: record.id,
                    sale_amount: record?.sale_amount,
                    lms_user_id: record?.lms_user_id,
                    email: record?.lead?.email || "test@imcsale.com",
                    name: record?.lead?.name || "NA",
                    id: record?.id,
                    payments: record?.payments,
                    region: record?.lead?.region
                  })
                }
                size="medium"
                icon={<DollarOutlined />}
              />
            </Tooltip>
          ) : null}

          <Tooltip placement="bottom" title="Activity">
            <Button
              onClick={() => setShowOrderActivty(record.id)}
              size="medium"
              icon={<ProfileOutlined />}
            />
          </Tooltip>
          {
            userEmail === "fathima-accounts@imcinstitute.ae"
              ?
              <Popconfirm
                placement="bottomRight"
                title="Delete this record"
                description="Are you sure you want to delete this record?"
                okText="Yes"
                onConfirm={() => handleRemoveOrder(record.id)}
                cancelText="No"
              >
                <Button size="medium" icon={<DeleteOutlined />} danger />
              </Popconfirm>
              :
              null
          }

        </Space>
      ),
    },
  ];

  return (
    <>
      {viewLeadDrawer ? (
        <ViewLeadInformation
          data={viewLeadDrawer}
          onClose={setViewLeadDrawer}
        />
      ) : null}
      {addPaymentDrawer ? (
        <AddPayment userEmail={userEmail} isOpen={addPaymentDrawer} onClose={setAddPaymentDrawer} />
      ) : null}
      {showOrderActivity ? (
        <OrderTimeline
          orderId={showOrderActivity}
          onClose={setShowOrderActivty}
        />
      ) : null}

      <div className="leads-action-wrapper">
        <div className="leads_action_buttons">
          <Tooltip placement="bottom" title="Reload">
            <Button onClick={refreshData} icon={<ReloadOutlined />} />
          </Tooltip>
          <Tag style={{ height: 25 }} color="green">Total Orders: {data?.data?.total || 0} </Tag>

          {/* <Tooltip placement="bottom" title="Import">
            <Button icon={<ImportOutlined />} />
          </Tooltip>
          <Tooltip placement="bottom" title="Export">
            <Button icon={<ExportOutlined />} />
          </Tooltip> */}
        </div>
        <div className="leads-action-wrapper-right">
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            allowClear
            onPressEnter={onSearch}
            loading={false}
            style={{
              maxWidth: 400,
            }}
          />
          <FilterOrders setParentFiler={setFilterApplied} parentFilter={filterApplied} filterCall={handleFilter} />
        </div>
      </div>
      <div className="lead-table">
        <Table
          // columns={columns}
          columns={filterColumns(columns)}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          dataSource={data?.data?.data || []}
          bordered={true}
          loading={isLoading}
          size="medium"
          tableLayout="fixed"
          showHeader={true}
          // rowSelection={true}
          pagination={{
            position: ["none", "bottomCenter"],
            total: data?.data?.total,
            onChange: (page, pageSize) => {
              window.scrollTo(0, 0);
              changePage(page, pageSize);
            },
          }}
          scroll={{ x: 1500 }}
          sticky={true}
        />
      </div>
    </>
  );
};
export default OrdersView;
