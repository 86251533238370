import { useState } from "react";
import { getOrderUserStatus } from "enums";
import moment from "moment";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Typography, DatePicker, Tooltip } from "antd";
const { Text } = Typography;
const OrderDate = ({ record = {}, onDateChange = () => {} }) => {
  const [isEdit, setIsEdit] = useState(false);
  const onChange = (value, dateString) => {
    if (dateString && dateString != "") {
      onDateChange(dateString);
    }
    setIsEdit(!isEdit);
  };
  const date =
    moment(record.order_date).format("dddd") +
    " , " +
    moment(record.order_date).format("MMM Do YYYY");

  return (
    <>
      {getOrderUserStatus(record.login_generated)}
      <br />
      {isEdit ? (
        <div
          style={{ marginTop: 10 }}
          className="d-flex gap-1 justify-content-center align-items-center"
        >
          <div style={{ width: "95%", overflow: "hidden" }}>
            <DatePicker
              allowClear={true}
              onChange={onChange}
              disabledDate={(current) => {
                let customDate = moment().add(1, "days").format("YYYY-MM-DD");
                return current && current > moment(customDate, "YYYY-MM-DD");
              }}
            />
          </div>
          <div onClick={() => setIsEdit(!isEdit)} className="cursor-pointer">
            <CloseOutlined />
          </div>
        </div>
      ) : (
        <div className="d-flex gap-1">
          <div style={{ width: "80%", overflow: "hidden" }}>
            <Tooltip placement="bottom" title={date}>
              <Text>{date}</Text>
            </Tooltip>
          </div>
          <div onClick={() => setIsEdit(!isEdit)} className="cursor-pointer">
            <EditOutlined />
          </div>
        </div>
      )}
    </>
  );
};
export default OrderDate;
