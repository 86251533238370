import { Collapse, Button } from "antd";
import GeneralSettings from "components/general-settings";
import SecuritySettings from "components/security-settings";
import CurrencySettings from "components/currency-settings";
import UpdateLeadCostModal from "components/update-lead-cost-modal";
import useProfileViewLogic from "business-logic/profile-view-logic";
import LeadsReport from "components/leads-report";
const SettingView = () => {
  const { userInfo } = useProfileViewLogic();
  const { email } = userInfo;
  return (
    <>
      <Collapse
        size="small"
        items={[
          {
            key: "1",
            label: "General Settings",
            children: (
              <div className="p-2">
                <GeneralSettings />
              </div>
            ),
          },
        ]}
      />
      <Collapse
        size="small"
        style={{ marginTop: 20 }}
        items={[
          {
            key: "1",
            label: "Security Settings",
            children: (
              <div className="p-2">
                <SecuritySettings />
              </div>
            ),
          },
        ]}
      />
      <Collapse
        size="small"
        style={{ marginTop: 20 }}
        items={[
          {
            key: "1",
            label: "Currency Settings Against AED",
            children: (
              <div className="p-2">
                <CurrencySettings />
              </div>
            ),
          },
        ]}
      />
      {
        email === "shifa-service@imcinstitute.ae"
          ?
          <Collapse
            size="small"
            style={{ marginTop: 20 }}
            items={[
              {
                key: "1",
                label: "Settings For Priyanka",
                children: (
                  <>
                    <div className="p-2" style={{ width: "max-content" }}>
                      <UpdateLeadCostModal />
                    </div>
                    <div className="p-2" style={{ width: "max-content" }}>
                      <LeadsReport />
                    </div>
                  </>
                ),
              },
            ]}
          />
          :
          null
      }
    </>
  );
};
export default SettingView;
