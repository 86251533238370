import { useState } from "react";
import { loginInUser } from "services";
import { App } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthorizationToken } from "utils/axios";
import { userLogin } from "store/reducers/userSlice";
import { ImagetoBase64 } from "utils/common-functions";
import { changeUserAvatar, changeUserPassword } from "services/user";
const useProfileViewLogic = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userData);
  const { message } = App.useApp();
  const onSubmit = (values) => {
    loginInUser(values).then((result) => {
      if (result.status === 1) {
        updateAuthorizationToken(result.data.token);
        dispatch(userLogin(result.data.user));
        message.open({
          type: "success",
          content: result.message,
        });
      } else if (result.response?.data?.status === 0) {
        message.open({
          type: "error",
          content: result.response.data.message,
        });
      }
    });
  };

  const changeAvatar = (file) => {
    ImagetoBase64(file, (result) => {
      const formData = new FormData();
      formData.append("file", result);
      changeUserAvatar(userInfo.id, formData).then((result) => {
        if (result?.status === 1) {
          dispatch(userLogin(result.data.user));
          message.open({
            type: "success",
            content: result.message,
          });
        } else {
          message.open({
            type: "error",
            content: "An Error Occured, Please Try Again",
          });
        }
      });
    });
  };

  const onSubmitChangePassword = (values, cb = () => { }) => {
    changeUserPassword(userInfo.id, { password: values.password }).then(
      (result) => {
        if (result.status === 1) {
          message.open({
            type: "success",
            content: result.message,
          });
          cb();
        }
      }
    );
  };



  return { onSubmit, userInfo, changeAvatar, onSubmitChangePassword };
};
export default useProfileViewLogic;
