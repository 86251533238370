import Axios from "axios";
import { apiBaseURL } from "enums";
import { setupCache } from "axios-cache-adapter";

let clearReduxCallback = undefined;

const axios = Axios.create({
  baseURL: apiBaseURL,
});
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  debug: true,
  exclude: { query: false },
});
const cachedAxios = Axios.create({
  baseURL: apiBaseURL,
  adapter: cache.adapter,
});
axios.CancelToken = Axios.CancelToken;
axios.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error.response?.status === 500) {
    }
    // if user is unauthorized
    if (error.response?.status === 401) {
      await localStorage.removeItem("user");
      await localStorage.removeItem("user_role");
      await localStorage.removeItem("user_verif");
      if (clearReduxCallback !== undefined) {
        clearReduxCallback();
      }
    }
    return error;
  }
);


export const setClearRedux = (callback) => {
  clearReduxCallback = callback;
};

export const updateAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    cachedAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete cachedAxios.defaults.headers.common["Authorization"];
  }
};

export { axios, cachedAxios, cache };
