import { loginInUser } from "services";
import { useState } from "react";
import { App } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthorizationToken } from "utils/axios";
import { userLogin, userLogOut } from "store/reducers/userSlice";
import { useNavigateInApp } from "hooks";
const useLoginViewLogic = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const [loading,setLoading]=useState(false);
  const { message } = App.useApp();
  const navigate = useNavigateInApp();
  const onSubmit = (values) => {
    setLoading(true);
    loginInUser(values).then((result) => {
      setLoading(false);
      if (result?.status === 1) {
        updateAuthorizationToken(result.data.token);
        dispatch(userLogin(result.data.user));
        message.open({
          type: "success",
          content: result.message,
        });
      } else if (result.response?.data?.status === 0) {
        message.open({
          type: "error",
          content: result.response.data.message,
        });
      }
    });
  };
  const userSignOut = () => {
    updateAuthorizationToken();
    dispatch(userLogOut());
    message.open({
      type: "success",
      content: "User Logout Successfully",
    });
    navigate("/");
  };
  return { onSubmit, userSignOut, userInfo,loading };
};
export default useLoginViewLogic;
