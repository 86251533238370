import { useState } from "react";
import {
  getOrders,
  savePayment,
  updateOrder,
  uploadPaymentReceipt,
  addUserToLms,
  removeOrder
} from "services";
import { App } from "antd";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import useProfileViewLogic from "business-logic/profile-view-logic";
import { useQuery } from "react-query";
import { default_password_lms_users, user_roles, getOrderPaymentStatus } from "enums";

const useOrdersViewLogic = (mainComponent = false) => {
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userInfo } = useProfileViewLogic();
  const { user_type: currentRole, email: userEmail } = userInfo;
  const [viewLeadDrawer, setViewLeadDrawer] = useState(false);
  const [showOrderActivity, setShowOrderActivty] = useState(false);
  const [addPaymentDrawer, setAddPaymentDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterApplied, setFilterApplied] = useState(0);
  const [params, setParams] = useState({
    page: searchParams.get("page") || 1,
    limit: searchParams.get("limit") || 10,
    search: searchParams.get("search") || "",
    filters: searchParams.get("search") || "{}",
    total: 0,
  });
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["/order-view", params],
    queryFn: () => getOrders(params),
    keepPreviousData: false,
  });

  const refreshData = () => {
    refetch();
  };

  const handleUploadPaymentReceipt = (selectedFile, form) => {
    let formData = new FormData();
    formData.append("payment_receipt", selectedFile);
    uploadPaymentReceipt(formData).then((result) => {
      if (result?.status === 1) {
        const parts = result.data.path.split("/");
        form.setFieldsValue({ payment_receipt: parts[parts.length - 1] });
        // form.setFieldsValue({ payment_receipt: result.data.file_name });
        message.open({
          type: "success",
          content: result.message,
        });
      }
    });
  };

  const handleAddPaymentSubmit = (form, cb = () => { }, order, payment_status, payment_amount) => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        savePayment({
          ...values,
          order_id: order.order_id,
          sale_amount: order.sale_amount,
          payment_amount: payment_amount,
          payment_method: values.payment_method ? values.payment_method : "NA",
          remarks: values.remarks,
          payment_currency: "AED",
          user_id: userInfo.id,
          payment_date: moment(),
          sale_amount: order.sale_amount,
          payment_receipt: "",
          payment_status
        }).then((result) => {
          if (result.status === 1) {
            //  Automating User Registration Flow Based on Payment if it's greater than  25%
            // Checking if user is already generated against the order
            // Checking if total payment is more then 33%
            if (!order.lms_user_id && result.data >= order?.region === 3 ? 25 : 33) {
              // Preparing Payload for the Api
              const payload = {
                order_id: order.order_id,
                email: order.email,
                password: default_password_lms_users,
                name: order.name,
              };
              // Calling Api
              addUserToLms(payload).then((result) => {
                if (!result.status === 1) {
                  message.open({
                    type: "error",
                    content: result.message,
                  });
                }
              });
            }
            message.open({
              type: "success",
              content: result.message,
            });
            setLoading(false);
            form.resetFields();
            refetch();
            cb();
          }
        });
      })
      .catch((errorInfo) => { });
  };

  const changePage = (page, limit) => {
    handleSearchParams("page", page);
    handleSearchParams("limit", limit);
    setParams({ ...params, page: page, limit: limit });
  };

  const handleSearchParams = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const openPaymentReceipt = (file_name) => {
    window.open(
      `https://api.imclearning.com/public/uploads/${file_name}`,
      "_blank",
      "noreferrer"
    );
    // window.open(`http://127.0.0.1/pdf/${url}`, '_blank', 'noreferrer');
  };

  const handleUpdateOrder = (value, order_id, key) => {
    updateOrder({
      order_id: order_id,
      value: value,
      key: key,
      user_id: userInfo.id,
    }).then((result) => {
      if (result.status === 1) {
        message.open({
          type: "success",
          content: result.message,
        });
        refetch();
      }
    });
  };

  const onSearch = (e) => {
    const searchValue = e === "" ? "" : e.target.value;
    handleSearchParams("search", searchValue);
    handleSearchParams("page", 1);
    setParams({ ...params, search: searchValue, page: 1 });
    // refreshData();
  };

  const handleFilter = (filterValue) => {
    handleSearchParams("filters", filterValue);
    setParams({ ...params, filters: filterValue, page: 0 });
  }

  const isPaymentButtonDisabled = (sale_amount, payments) => {
    if (getOrderPaymentStatus(sale_amount, payments) === 100 || sale_amount === null) return true;
    else return false;
  };

  const filterColumns = (data) => {
    const allowedColumns = user_roles[currentRole].allowedOrderColumns;
    // Ensure data is an array of objects
    if (!Array.isArray(data) || data.length === 0 || typeof data[0] !== 'object') {
      return data; // Return data as is if it doesn't match the expected format
    }
    const filteredColumns = [];
    // Filter the columns based on the allowedColumns list
    data.map((row) => {
      const found = allowedColumns.find(x => x === row.title)
      if (found) {
        filteredColumns.push(row)
      }
    });
    return filteredColumns;
  }

  const handleRemoveOrder = (id) => {
    removeOrder(id).then((result) => {
      if (result.status === 1) {
        refetch();
      }
    })
  }

  return {
    isLoading,
    data,
    refreshData,
    viewLeadDrawer,
    setViewLeadDrawer,
    params,
    changePage,
    addPaymentDrawer,
    setAddPaymentDrawer,
    handleAddPaymentSubmit,
    openPaymentReceipt,
    handleUpdateOrder,
    showOrderActivity,
    setShowOrderActivty,
    handleUploadPaymentReceipt,
    onSearch,
    isPaymentButtonDisabled,
    loading,
    filterColumns,
    currentRole,
    handleRemoveOrder,
    handleFilter,
    userEmail,
    filterApplied, 
    setFilterApplied
  };
};
export default useOrdersViewLogic;
