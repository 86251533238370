import {useState} from "react";
import { App } from "antd";
import { editPayment } from "services";
const useEditPayment = () => {
  const [isOpen, setOpen] = useState(false);
  const handleEditPayment = (payment, cb = () => {}) => {
    editPayment(payment).then((result) => {
        if(result.status===1)
        {
            cb();
            setOpen(false);
        }
    });
  };
  return { handleEditPayment,isOpen,setOpen };
};
export default useEditPayment;
