import { useState, useEffect } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button, Modal, Typography, Row, Col, Badge, DatePicker } from "antd";
import { Select } from "antd";
import { getAllUser } from "services";
import UserTile from "components/user-tile";
// import { payment_methods } from "enums";
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const FilterOrders = ({ filterCall = () => { },parentFilter=0,setParentFiler=()=>{} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [relationshipManagers, setRelationShipManagers] = useState([]);
  const [region, setRegion] = useState([]);
  const [rangePicker, setRangePicker] = useState([]);
  const [marketers, setMarketers] = useState([]);
  const [platform, setPlatforms] = useState([]);
  const [filterApplied, setFilterApplied] = useState(0);
  useEffect(() => {
    getAllUser().then((result) => {
      if (result.status === 1) {
        setUsers(result.data);
      }
    });
  }, []);
  useEffect(() => {
    if(parentFilter>0){
      setFilterApplied(1);
    }
  }, [parentFilter]);
  const handleChange = (propName, propValue) => {
    if (propName === "relationshipManagers") {
      let payload = [];
      propValue.forEach((item) => {
        const filteredUser = users.find((x) => x.f_name === item);
        payload.push(filteredUser.id);
      })
      setRelationShipManagers(payload);
    } else if (propName === "region") {
      setRegion(propValue);
    } else if (propName === "platform") {
      setPlatforms(propValue);
    } else {
      setMarketers(propValue);
    }
  };
  const handleRangeChange = (value, ranges) => {
    setRangePicker(ranges);
  };
  const handleOnFilterLeads = () => {
    let filter = {};
    let filterApplied = 0;
    let rm = [];
    let re = [];
    let mt = [];
    let pf = [];
    if (relationshipManagers.length > 0) {
      rm = relationshipManagers.join(",");
      filter.relationshipManagers = rm;
      filterApplied++;
    }
    if (region.length > 0) {
      re = region.join(",");
      filter.region = re;
      filterApplied++;
    }

    if (marketers.length > 0) {
      mt = marketers.join(",");
      filter.marketers = mt;
      filterApplied++;
    }
    if (platform.length > 0) {
      pf = platform.join(",");
      filter.platforms = pf;
      filterApplied++;
    }
    if (rangePicker.length > 0) {
      if (rangePicker[0] !== "" && rangePicker[1] !== "") {
        filter.range = rangePicker.join(",");
        filterApplied++;
      }
    }
    setFilterApplied(filterApplied);
    setParentFiler(0);
    const stringifyFilter = JSON.stringify(filter);
    filterCall(stringifyFilter);
    setIsOpen(!isOpen);
  };
  const handleRemoveFilter = () => {
    setFilterApplied(0);
    setParentFiler(0);
    filterCall("{}");
    setMarketers([]);
    setRelationShipManagers([]);
    setRegion([]);
  }
  return (
    <>
      <Modal
        title="Filter Orders"
        centered
        open={isOpen}
        onOk={handleOnFilterLeads}
        okText="Filter Orders"
        onCancel={() => setIsOpen(!isOpen)}
      >
        <Row style={{ marginBottom: 20 }} gutter={16}>
          <Col span={24}>
            <Text strong>Relationship Manager:</Text>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                marginTop: 10,
              }}
              placeholder="Please select"
              onChange={(value) => handleChange("relationshipManagers", value)}
              optionLabelProp="label"
            >
              {users &&
                users
                  .filter((x) => ["user", "manager"].includes(x.user_type))
                  .sort(function (a, b) {
                    if (a.f_name < b.f_name) {
                      return -1;
                    }
                    if (a.f_name > b.f_name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item, index) => (
                    <Option
                      key={`User Relationship Manager Dropdown ${item.f_name + index
                        }`}
                      // value={item.id}
                      value={item.f_name}
                      label={item.f_name + " " + item.l_name}
                    >
                      <UserTile
                        avatarSize={30}
                        alignment="row"
                        assignedUser={item}
                      />
                    </Option>
                  ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }} gutter={16}>
          <Col span={24}>
            <Text strong>Region:</Text>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                marginTop: 10,
              }}
              placeholder="Please select"
              onChange={(value) => handleChange("region", value)}
              options={[
                { label: "Abu Dhabi", value: 1 },
                { label: "Pakistan", value: 3 },
                { label: "Noida", value: 2 },
                { label: "Banglore", value: 4 },
              ]}
            />
          </Col>
        </Row>
        {/* <Row style={{ marginBottom: 20 }} gutter={16}>
          <Col span={24}>
            <Text strong>Platform:</Text>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                marginTop: 10,
              }}
              placeholder="Please select"
              onChange={(value) => handleChange("platform", value)}
            >
              {payment_methods
                .sort(function (a, b) {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .map((gateway) => (
                  <Option
                    key={`Payment Gateways ${gateway.title}`}
                    value={gateway.title}
                  >
                    {gateway.title}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row> */}
        <Row style={{ marginBottom: 20 }} gutter={16}>
          <Col span={24}>
            <Text strong>Marketer:</Text>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                marginTop: 10,
              }}
              placeholder="Please select"
              onChange={(value) => handleChange("marketers", value)}
            >
              {users &&
                users
                  .filter((x) => ["marketer"].includes(x.user_type))
                  .sort(function (a, b) {
                    if (a.f_name < b.f_name) {
                      return -1;
                    }
                    if (a.f_name > b.f_name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item, index) => (
                    <Option
                      key={`User Marktere Dropdown ${item.f_name + index}`}
                      value={item.f_name.toLowerCase()}
                      label={item.f_name + " " + item.l_name}
                    >
                      <UserTile
                        avatarSize={30}
                        alignment="row"
                        assignedUser={item}
                      />
                    </Option>
                  ))}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }} gutter={16}>
          <Col span={24}>
            <Text strong>Date To:</Text>
            <RangePicker
              style={{
                width: "100%",
                marginTop: 10,
              }}
              onChange={handleRangeChange}
            />
          </Col>
        </Row>
      </Modal>
      <Badge count={filterApplied}>
        <Button onClick={() => setIsOpen(!isOpen)} icon={<FilterOutlined />}>
          Add Filter
        </Button>
      </Badge>
      {
        filterApplied > 0
          ?
          <Button danger type="dashed" onClick={handleRemoveFilter}>
            Remove Filters
          </Button>
          :
          null
      }
    </>
  );
};
export default FilterOrders;
