import useBatchView from "./useBatchView";
import { DeleteOutlined } from "@ant-design/icons";
import {
  Drawer,
  Select,
  Table,
  Divider,
  Typography,
  Button,
  Popconfirm,
} from "antd";
const { Text } = Typography;
const BatchViewDrawer = ({ isOpen, onClose, order_id,user_email }) => {
  const {
    enrolled_batches,
    handleEnrolledBatch,
    enrolledBatchesLoading,
    handleRemovefromBatch,
    getUniqueBatches,
  } = useBatchView(order_id,user_email);
  const columns = [
    {
      title: "Batch#",
      dataIndex: "name",
      key: "id",
      width: 80,
      render: (_, record) => (
        <>
          <Text>{record?.batch_details?.batch_no}</Text>
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "title",
      width: 320,
      render: (_, record) => (
        <>
          <Text>{record?.batch_details?.batch_title}</Text>
        </>
      ),
    },
    {
      title: "Trainee",
      dataIndex: "trainee_name",
      width: 150,
      render: (_, record) => (
        <>
          <Text>{record?.batch_details?.trainer_name}</Text>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 60,
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Remove User from batch"
          description="Are you sure to remove the user from batch?"
          onConfirm={() => handleRemovefromBatch(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      <Drawer
        title="Batches"
        width={720}
        onClose={() => onClose(false)}
        open={isOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Select
          placeholder="Add a new batch"
          onChange={(value) => handleEnrolledBatch(value)}
          style={{
            width: "100%",
          }}
          options={getUniqueBatches()}
        />
        <Divider>Enrolled Batches</Divider>
        <Table
          pagination={false}
          columns={columns}
          dataSource={enrolled_batches}
          loading={enrolledBatchesLoading}
          scroll={{ x: 600 }}
          sticky={true}
          size="small"
          tableLayout="fixed"
          showHeader={true}
        />
      </Drawer>
    </>
  );
};
export default BatchViewDrawer;
