import { Typography, InputNumber, Select, Button } from "antd";
import { useState } from "react";
const { Text } = Typography;
const { Option } = Select;
const RevenueHandler = ({ record = {}, onUpdate = () => { }, userEmail }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("AED");
  const [revenueAmount, setRevenueAmount] = useState("");
  const selectAfter = (
    <Select
      defaultValue="AED"
      onChange={(value) => setSelectedCurrency(value)}
      style={{
        width: 70,
      }}
    >
      <Option value="AED">AED</Option>
      <Option value="USD">USD</Option>
      <Option value="PKR">PKR</Option>
      <Option value="INR">INR</Option>
      <Option value="SAR">SAR</Option>
      <Option value="OMAN">OMAN</Option>
      <Option value="KWD">Kuwait</Option>
      <Option value="BHD">Behrain</Option>
      <Option value="PHP">Phillipe</Option>
      <Option value="EGP">Egypt</Option>
    </Select>
  );
  const handleCurrencyUpdate = async (e) => {
    const { value } = e.target;
    const parsedValue = parseFloat(value);
    const currencies = await localStorage.getItem("currencies") || JSON.stringify('{}');
    const parsedCurrencies = JSON.parse(JSON.parse(currencies));
    let finalValue;
    if (selectedCurrency !== "AED") {
      finalValue = parsedValue / parsedCurrencies[selectedCurrency] || 1;
      onUpdate(parsedCurrencies[selectedCurrency], record.id, "conversion_rate");
    } else {
      finalValue = parsedValue;
    }
    onUpdate(selectedCurrency, record.id, "sale_amount_currency");
    onUpdate(finalValue, record.id, "sale_amount");
  };
  return (
    <>
      {record?.sale_amount ? (
        <Text
          editable={{
            onChange: (value) => {
              onUpdate(value, record?.id, "sale_amount");
            },
          }}
        >
          {record?.sale_amount} AED
        </Text>
      ) : (
        <>
          <div className="d-flex" style={{ flexDirection: "column", gap: 5 }}>
            <InputNumber
              onPressEnter={(e) => handleCurrencyUpdate(e)}
              min={1}
              //   addonAfter={<Text>AED</Text>}
              onChange={(value) => setRevenueAmount(parseFloat(value))}
              controls
              addonAfter={selectAfter}
            />
            {
              userEmail === "fathima-accounts@imcinstitute.ae"
                ?
                <>
                  <Button onClick={() => handleCurrencyUpdate({ target: { value: revenueAmount } })} disabled={revenueAmount === "" || isNaN(revenueAmount)} size="small" type="primary">Save</Button>
                </>
                :
                null
            }
          </div>
        </>
      )}
    </>
  );
};
export default RevenueHandler;
