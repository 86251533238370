import {
  Drawer,
  Select,
  Table,
  Divider,
  Typography,
  Button,
  Popconfirm,
  Space,
} from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import useCourseView from "./useCourseView";
import moment from "moment";
import CertifiedUserView from "components/certified-user-view";
const { Text } = Typography;
const CourseDrawer = ({ isOpen, onClose, order_id, user_id, user_email }) => {
  const {
    getUniqueCourses,
    handleEnrolledCourse,
    enrolled_courses,
    enrolled_courses_loading,
    handleRemoveEnrolledCourse,
    handleAddCertificate,
  } = useCourseView(order_id, user_id, user_email);
  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "id",
      width: 250,
      ellipse:true,
      render: (_, record) => (
        <>
          <Text>{record?.course?.title}</Text>
        </>
      ),
    },
    {
      title: "Enrolled On",
      dataIndex: "enrolled_at",
      width: 120,
      render: (_, record) => (
        <>
          <Text>{moment(record?.enrolled_at).format("MMMM Do YYYY")}</Text>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 120,
      key: "action",
      render: (_, record) => (
        <>
          <Space>
            <Popconfirm
              title="Release Certificate"
              description="Are you sure to release certificate against this course?"
              // onConfirm={() => handleAddCertificate(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small">
                Release Cert
                </Button>
            </Popconfirm>
            {/* <Popconfirm
              title="Add Certificate"
              description="Are you sure to add certificate against this course?"
              onConfirm={() => handleAddCertificate(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="dashed"
                size="small"
                icon={<PlusCircleOutlined />}
              />
            </Popconfirm> */}
            <Popconfirm
              disabled={record.certificates.length > 0}
              title="Remove User from batch"
              description="Are you sure to remove the user from batch?"
              onConfirm={() => handleRemoveEnrolledCourse(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={record.certificates.length > 0} size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];
  const expandedRowRender = (enrolled_course) => {
    return <CertifiedUserView data={enrolled_course} />;
  };
  return (
    <>
      <Drawer
        title="Courses"
        width={720}
        onClose={() => onClose(false)}
        open={isOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Select
          value=""
          placeholder="Add a new course"
          onChange={(value) => handleEnrolledCourse(value)}
          style={{
            width: "100%",
          }}
          showSearch
          // filterOption={(input, option) =>
          // (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          // }
          options={[
            {
              label: <span>Combo Courses</span>,
              title: "Combo Courses",
              options: combo_courses_enum
            },
            {
              label: <span>All Courses</span>,
              title: "All Courses",
              options: getUniqueCourses()
            }
          ]}

        />
        <Divider>Enrolled Courses</Divider>
        <Table
          pagination={false}
          columns={columns}
          dataSource={enrolled_courses}
          loading={enrolled_courses_loading}
          scroll={{ x: 600 }}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ["0"],
            rowExpandable: (record) => record.certificates.length !== 0,
          }}
          sticky={true}
          size="small"
          tableLayout="fixed"
          showHeader={true}
        />
      </Drawer>
    </>
  );
};
export default CourseDrawer;


const combo_courses_enum = [
  {
    value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }]),
    label: "GB + BB",
  },
  {
    value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 10, courseTitle: "PMP Certification Training" }]),
    label: "GB + PMP",
  },
  {
    value: JSON.stringify([{ courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }, { courseId: 10, courseTitle: "PMP Certification Training" }]),
    label: "BB + PMP",
  },
  {
    value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }, { courseId: 10, courseTitle: "PMP Certification Training" }]),
    label: "GB + BB + PMP",
  },
  {
    value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }, { courseId: 12, courseTitle: "Lean Six Sigma Master Black Belt" }]),
    label: "GB + BB + MBB",
  },
  {
    value: JSON.stringify([{ courseId: 87, courseTitle: "Certified Supply Chain Foundation" }, { courseId: 97, courseTitle: "Certified Supply Chain Specialist" }]),
    label: "CSCF + CSCS",
  },
  {
    value: JSON.stringify([{ courseId: 87, courseTitle: "Certified Supply Chain Foundation" }, { courseId: 98, courseTitle: "Certified Supply Chain Expert" }, { courseId: 97, courseTitle: "Certified Supply Chain Specialist" }]),
    label: "CSCF + CSCS + CSCE",
  },
]