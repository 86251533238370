import { axios } from "utils/axios";
const controller_name = "order/";

export const getOrders = (params) => {
  let result = axios
    .get(`${controller_name}get`, {
      params: {
        page: params.page,
        limit: params.limit,
        search: params.search,
        filters: params.filters
      }
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const updateOrder = (data) => {
  let result = axios
    .post(`${controller_name}update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};


export const removeOrder = (id) => {
  let result = axios
    .get(`${controller_name}delete/${id}`)
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};


export const updateLeadCosts = (params, data) => {
  const { campaign, currency } = params;
  let result = axios
    .post(`lead/upload-excel-file`, data, { params: { campaign: campaign, currency: currency } })
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};


export const getLeadsReport = () => {
  let result = axios
    .get(`lead/download-leads-report`)
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};



