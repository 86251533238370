import { axios } from "utils/axios";
const controller_name = "activity/";

export const getActivity = (order_id) => {
  let result = axios
    .get(`${controller_name}get/${order_id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};
