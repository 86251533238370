import { useState } from "react";
import { sendCredientialsEmail, updateLmsUser } from "services";
import { App } from "antd";
const useServiceModal = () => {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [resetPassowrdLoading, setResetLoading] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [emailLoading, setEmailLoading] = useState(false);
    const [inputError, setInputError] = useState(false);

    const handleSendEmail = (user_id, order_id) => {
        setLoading(true);
        sendCredientialsEmail({ userId: user_id, orderId: order_id }).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: result.message,
                });
            }
            setLoading(false);
        })
    }
    const updatePassword = (user_id) => {
        setResetLoading(true);
        const params = {
            key: "password",
            value: "$2a$12$mLQFybsUAvrbWr5melpU0e8vDDWEPZZ9/hYOj0Ju9/39V8E.SQniW",
            id: user_id
        };
        updateLmsUser(params).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: result.message,
                });
            }
            setResetLoading(false);
        })
    }
    const updateLMSEmail = (user_id) => {
        const isValid = email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (isValid) {
            setEmailLoading(true);
            const params = {
                key: "email",
                value: email,
                id: user_id
            };
            updateLmsUser(params).then((result) => {
                if (result.status === 1) {
                    message.open({
                        type: "success",
                        content: result.message,
                    });
                } else {
                    message.open({
                        type: "error",
                        content: "Email Already Registered With IMC",
                    });
                }
                setEmailLoading(false);
                setChangeEmail(false);
            })
        } else {
            setInputError(true);
        }

    }
    return { loading, handleSendEmail, updatePassword, resetPassowrdLoading, changeEmail, setChangeEmail, email, setEmail, updateLMSEmail, emailLoading, inputError }
}
export default useServiceModal;