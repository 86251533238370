import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserDetails, getOrders } from "services";
const useProfileView = () => {
  let { id } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [recentOrders, setRecentOrders] = useState({
    loading: true,
    limit: 10,
  });
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: "",
    filters: { relationshipManagers: id },
    total: 0,
  });
  useEffect(() => {
    getUserDetails(id).then((result) => {
      if (result.status === 1) {
        setUserDetails(result.data);
      }
      setLoading(false);
    });
    getUserOrders();
  }, []);

  const getUserOrders = () => {
    let updatedParams = {...params};
    if(params.total!==0)
    {
      updatedParams.limit = updatedParams.limit + 10;
    }
    getOrders(updatedParams).then((result) => {
      if (result.status === 1) {
        const {limit,total}=result.data;
        setRecentOrders({
          loading: false,
          ...result.data,
        });
        setParams({
          ...params,
          limit,
          total
        })
      }
    });
  };
  return {
    loading,
    userDetails,
    getUserOrders,
    order: recentOrders,
  };
};
export default useProfileView;
