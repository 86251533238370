import { axios } from "utils/axios";
const controller_name = "dashboard/";

export const getDashboard = () => {
  let result = axios
    .get(`${controller_name}get`)
    .then((res) => {
      return res.data ? res.data : res;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const getDashboardGraph = (params) => {
  let result = axios
    .get(`${controller_name}get-graph`, {
      params: {
        key: params?.key,
        startDate: params?.startDate,
        endDate: params?.endDate,
      },
    })
    .then((res) => {
      return res.data ? res.data : res;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};
