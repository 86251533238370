import {
  getAllBatches,
  saveEnrolledBatch,
  getAllEnrolledBatches,
  removeEnrolledBatch,
} from "services";
import { App } from "antd";
import { useQuery } from "react-query";
import { json } from "react-router-dom";
const useBatchView = (order_id, user_email) => {
  const { message } = App.useApp();
  const { isError, data } = useQuery({
    queryKey: ["/get-all-batches"],
    queryFn: () => getAllBatches(),
    keepPreviousData: true,
  });
  const {
    isLoading: enrolledBatchesLoading,
    isError: enrolledBatchesError,
    data: enrolledBatches,
    refetch,
  } = useQuery({
    queryKey: ["/get-enrolled-batches"],
    queryFn: () => getAllEnrolledBatches(order_id),
    keepPreviousData: true,
  });
  if (isError || enrolledBatchesError) {
    message.open({
      type: "error",
      content: "Something went wrong, please try again later!",
    });
  }
  const handleEnrolledBatch = (value) => {
    const parsedValue=JSON.parse(value);
    saveEnrolledBatch({
      batch_id: parsedValue.id,
      order_id: order_id,
      user_email: user_email,
      batch_title:parsedValue.title,
    }).then((result) => {
      if (result.status === 1) {
        refetch();
      }
    });
  };
  const handleRemovefromBatch = (id) => {
    removeEnrolledBatch(id).then((result) => {
      if (result.status === 1) {
        refetch();
      }
    });
  };
  const getUniqueBatches = () => {
    const select_options = [];
    data?.data.map((item) => {
      const found = enrolledBatches?.data?.find(
        (result) => result.batch_details.id == item.id
      );
      if (!found) {
        select_options.push({
          value: JSON.stringify({ id: item.id, title: item.batch_title }),
          label: item.batch_title + " - Batch # " + item?.batch_no,
        });
      }
    });
    return select_options;
  };
  return {
    all_batches: data?.data || [],
    enrolled_batches: enrolledBatches?.data || [],
    handleEnrolledBatch,
    enrolledBatchesLoading,
    handleRemovefromBatch,
    getUniqueBatches,
  };
};
export default useBatchView;
