import {
  Avatar,
  Typography,
  Button,
  Tag,
  Spin,
  Divider,
  List,
  Skeleton,
} from "antd";
import { MailOutlined, AuditOutlined, PhoneOutlined } from "@ant-design/icons";
import useProfileView from "business-logic/useProfileView";
import InfiniteScroll from "react-infinite-scroll-component";
import { getRegionInformation } from "enums";
import OrderPaymentStatus from "components/order-payment-tile";
const { Title, Text } = Typography;
const ProfileView = () => {
  const { loading, userDetails, getUserOrders, order } = useProfileView();
  const { f_name, l_name, profile_image, email, user_type, region } =
    userDetails;
  return (
    <>
      <Spin spinning={loading}>
        <div className="profile-page">
          <div className="profile-background"></div>
          <div className="profile-information">
            <div className="profile-box">
              <Avatar
                size={120}
                src={
                  profile_image === "_defaultUser.png"
                    ? "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-2048x1949-pq9uiebg.png"
                    : profile_image
                }
              />
              <div className="profile-details">
                <Title className="m-0" level={3}>
                  {f_name + " " + l_name}
                </Title>
                <Tag
                  style={{ maxWidth: "fit-content" }}
                  color={getRegionInformation(region || 0)?.color}
                >
                  {getRegionInformation(region || 0)?.title}
                </Tag>
                <div className="d-flex justify-content-center gap-1">
                  <Button icon={<AuditOutlined />}>
                    {user_type_enums[user_type]}
                  </Button>
                  <Button icon={<MailOutlined />}>{email}</Button>
                </div>
              </div>
            </div>
            <div className="payment-summary">
              <div className="summary-header">
                <div className="summary-text-container">
                  <Text type="secondary">Gross Revenue</Text>
                  <Title level={3}>0 AED</Title>
                </div>
                <div className="summary-tags">
                  <Tag color="green">Net Revenue : 0 AED</Tag>
                  <Tag color="red">Deduction : 0 AED</Tag>
                </div>
              </div>
            </div>
          </div>
          <div id="scrollableDiv" className="recent-orders">
            {order.loading ? null : (
              <InfiniteScroll
                dataLength={order?.data.length || [].length}
                next={getUserOrders}
                hasMore={order?.limit < order.total}
                loader={
                  <Skeleton
                    avatar
                    paragraph={{
                      rows: 2,
                    }}
                    active
                  />
                }
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
              >
                <List
                  dataSource={order?.data || []}
                  renderItem={(item) => {
                    const grossRevenue = item.payments
                      .filter((x) => x.payment_status !== 2)
                      .reduce(
                        (total, payment) => total + payment.payment_amount,
                        0
                      );

                    const deductionAmount = item.payments
                      .filter((x) => x.payment_status === 2)
                      .reduce(
                        (total, payment) => total + payment.payment_amount,
                        0
                      );

                    const netRevenue = grossRevenue - deductionAmount;
                    return (
                      <List.Item key={item?.id}>
                        <List.Item.Meta
                          className="align-items-center"
                          avatar={
                            <Avatar
                              size={45}
                              style={{
                                backgroundColor: "#f56a00",
                              }}
                            >
                              {item?.lead?.name?.charAt(0)?.toUpperCase()}
                            </Avatar>
                          }
                          title={item?.lead?.name}
                          description={
                            <>
                              <div className="d-flex gap-1">
                                <Button size="medium" icon={<PhoneOutlined />}>
                                  {item?.lead?.phone}
                                </Button>
                                <Button size="medium" icon={<MailOutlined />}>
                                  {item?.lead?.email}
                                </Button>
                              </div>
                            </>
                          }
                        />
                        <div className="d-flex gap-1 align-items-center">
                          {item?.sale_amount ? (
                            <>
                              <div className="payment-summary">
                                <div className="d-flex gap-1">
                                  <Text>{item.sale_amount} AED</Text>
                                  <OrderPaymentStatus record={item} />
                                  <Tag color="green">Net Revenue : {netRevenue}AED</Tag>
                                  <Tag color="red">Deduction : {deductionAmount ? deductionAmount.toFixed(2) : null} AED</Tag>
                                </div>
                              </div>
                            </>
                          ) : (
                            <Tag color="red">Accounts Pending</Tag>
                          )}
                        </div>
                      </List.Item>
                    );
                  }}
                />
              </InfiniteScroll>
            )}
          </div>
        </div>
      </Spin>
    </>
  );
};
export default ProfileView;

const user_type_enums = {
  user: "Relationship Manager",
  manager: "Corporate Manager",
};
