import { Typography, Select, Spin, Button, Space, Badge } from "antd";
import useAdminLineChartGraph from "./useAdminLineChartGraph";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

const { Title, Text } = Typography;
const RADIAN = Math.PI / 180;
const graphData = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const AdminLineChartGraph = () => {
  const { isLoading, data,handleFilterChange } = useAdminLineChartGraph();
  return (
    <Spin spinning={isLoading} delay={500}>
      <div className="graph-card">
        <Title level={4}>Conversion</Title>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={100} height={100}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="graph-card-badges">
          <Space size={15} direction="horizontal">
            <Badge
              color="#0088FE"
              status="processing"
              text={`Total Orders: ${data[0]?.value}`}
            />
            <Badge
              color="#00C49F"
              status="processing"
              text={`Total Payments: ${data[1]?.value}`}
            />
            <Badge
              color="#FFBB28"
              status="processing"
              text={`Total Refund: ${data[2]?.value}`}
            />
          </Space>
        </div>
        <div className="graph-card-footer">
          <Select
            defaultValue="7 days"
            style={{
              width: 120,
            }}
            bordered={false}
            onChange={(value) => handleFilterChange(value)}
            options={[
              {
                value: "today",
                label: "Today",
              },
              {
                value: "yesterday",
                label: "Yesterday",
              },
              {
                value: "7days",
                label: "Last 7 days",
              },
              {
                value: "28days",
                label: "Last 28 days",
              },
              {
                value: "90days",
                label: "Last 90 days",
              },
              {
                value: "custom",
                label: "Custom",
              },
            ]}
          />
          <Button type="text">View more details</Button>
        </div>
      </div>
    </Spin>
  );
};
export default AdminLineChartGraph;

// <div className="top-bar">
// <div className="top-bar-item top-bar-active">
//   <Text>Orders</Text>
//   <Title level={3}>147</Title>
//   <Statistic
//     value={11.28}
//     precision={2}
//     valueStyle={{
//       color: "#3f8600",
//       fontSize: 12,
//     }}
//     prefix={<ArrowUpOutlined />}
//     suffix="%"
//   />
// </div>
// <div className="top-bar-item">
//   <Text>Revenue</Text>
//   <Title level={3}>147</Title>
//   <Statistic
//     value={11.28}
//     precision={2}
//     valueStyle={{
//       color: "#3f8600",
//       fontSize: 12,
//     }}
//     prefix={<ArrowUpOutlined />}
//     suffix="%"
//   />
// </div>
// <div className="top-bar-item">
//   <Text>Payments</Text>
//   <Title level={3}>147</Title>
//   <Statistic
//     value={11.28}
//     precision={2}
//     valueStyle={{
//       color: "#3f8600",
//       fontSize: 12,
//     }}
//     prefix={<ArrowUpOutlined />}
//     suffix="%"
//   />
// </div>
// <div className="top-bar-item">
//   <Text>Refund</Text>
//   <Title level={3}>147</Title>
//   <Statistic
//     value={11.28}
//     precision={2}
//     valueStyle={{
//       color: "#3f8600",
//       fontSize: 12,
//     }}
//     prefix={<ArrowUpOutlined />}
//     suffix="%"
//   />
// </div>
// </div>
// <div className="line-break" />
// <ResponsiveContainer width="100%" height="100%">
// <LineChart
//   width={200}
//   height={100}
//   data={graphdata}
//   margin={{
//     top: 5,
//     // right: 30,
//     // left: 20,
//     bottom: 5,
//   }}
// >
//   <CartesianGrid strokeDasharray="3 3" />
//   <XAxis dataKey="name" />
//   <YAxis />
//   <Tooltip />
//   <Legend />
//   <Line
//     type="monotone"
//     dataKey="pv"
//     stroke="#8884d8"
//     activeDot={{ r: 8 }}
//   />
//   <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
// </LineChart>
// </ResponsiveContainer>
