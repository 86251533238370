import { axios } from "utils/axios";
const controller_name = "payment/";

export const savePayment = (data) => {
  let result = axios
    .post(`${controller_name}save`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const uploadPaymentReceipt = (data) => {
  let result = axios
    .post(`${controller_name}upload`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};


export const updatePayment = (data) => {
  let result = axios
    .post(`${controller_name}update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const editPayment = (data) => {
  let result = axios
    .post(`${controller_name}edit`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};