import React, { useState } from "react";
import { Button } from "antd";
import CourseDrawer from "./course-drawer";
const OrderCourses = ({ order_id, user_id, user_email }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="large"
        block
      >
        Course
      </Button>
      {isOpen ? (
        <CourseDrawer
          order_id={order_id}
          user_id={user_id}
          isOpen={isOpen}
          onClose={setOpen}
          user_email={user_email}
        />
      ) : null}
    </>
  );
};
export default OrderCourses;
