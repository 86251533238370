import { axios } from "utils/axios";
const controller_name = "batch-schedules/";

export const getAllBatches = () => {
  let result = axios
    .get(`${controller_name}get`)
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const getAllEnrolledBatches = (order_id) => {
  let result = axios
    .get(`${controller_name}get-enrolled-batches/${order_id}`)
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const saveEnrolledBatch = (data) => {
  let result = axios
    .post(`${controller_name}save`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const removeEnrolledBatch = (id) => {
  let result = axios
    .get(`${controller_name}remove-from-batch/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};
