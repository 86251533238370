import { Form, InputNumber, Button } from "antd";
import { available_currencies } from "enums";
import useLocalStorage from "hooks/useLocalStorage";
const CurrencySettings = () => {
  const [currencies, setCurrencies] = useLocalStorage("currencies", {});
  const onSubmit = (values) => {
    setCurrencies(JSON.stringify(values));
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <Form
          layout="horizontal"
          name="normal_login"
          className="login-form"
          onFinish={onSubmit}
        >
          {available_currencies.map((item) => (
            <Form.Item
              key={`Currency Setting Input Field ${item.label}`}
              label={item.label}
              name={item.value}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber style={{ minWidth: 150 }} prefix={item.symbol} />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default CurrencySettings;
