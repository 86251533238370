import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import useEditPayment from "./useEditPayment";
import { payment_methods } from "enums";
const { Option } = Select;
const EditPayment = ({ payment }) => {
  const { handleEditPayment, isOpen, setOpen } = useEditPayment();
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="dashed"
        size="small"
        icon={<EditOutlined />}
      />

      {isOpen ? (
        <Drawer
          title="Edit the new payment"
          width={720}
          onClose={() => setOpen(false)}
          open={isOpen}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={(values) => {
              const payload = {
                payment_id: payment.id,
                payment_method: values.payment_method
                  ? values.payment_method
                  : payment.payment_method,
                payment_amount: values.payment_amount
                  ? values.payment_amount
                  : payment.payment_amount,
                remarks: values.remarks,
                payment_date: values.payment_date
                  ? values.payment_date
                  : payment.payment_date,
              };
              handleEditPayment(payload);
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="payment_amount"
                  label="Paid Amount"
                  rules={[
                    {
                      required: false,
                      message: "Please enter paid amount",
                    },
                  ]}
                >
                  <Input
                    defaultValue={payment.payment_amount}
                    type="number"
                    placeholder="Please enter paid amount"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="payment_method"
                  label="Payment Method"
                  rules={[
                    {
                      required: false,
                      message: "Please enter payment Method",
                    },
                  ]}
                >
                  <Select
                    defaultValue={payment.payment_method}
                    placeholder="Please select an method"
                  >
                    {payment_methods
                      .sort(function (a, b) {
                        if (a.title < b.title) {
                          return -1;
                        }
                        if (a.title > b.title) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((gateway) => (
                        <Option
                          key={`Payment Gateways ${gateway.title}`}
                          value={gateway.title}
                        >
                          {gateway.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="payment_date"
                  label="Payment Date"
                  rules={[
                    {
                      required: false,
                      message: "Please enter payment date",
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="remarks"
                  label="Notes"
                  rules={[
                    {
                      required: false,
                      message: "please enter remarks",
                    },
                  ]}
                >
                  <Input.TextArea
                    defaultValue={payment.remarks}
                    rows={4}
                    placeholder="Please enter remarks"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Edit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      ) : null}
    </>
  );
};
export default EditPayment;
