/**
 * Routes
 * Contain Application Routes
 * Add Route : Add component in src/views then add a route with path and element
 */
import { Routes, Route } from "react-router-dom";
import OrdersView from "views/orders";
import SettingView from "views/settings";
import DashboardView from "views/dashboard";
import PaymentsView from "views/payments";
import TeamsView from "views/teams";
import LoginView from "views/login";
import ProfileView from "views/profile";
export const ApplicationRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardView />} />
        <Route path="/orders" element={<OrdersView />} />
        <Route path="/settings" element={<SettingView />} />
        <Route path="/profile/:id" element={<ProfileView />} />
        <Route path="/payments" element={<PaymentsView />} />
        <Route path="/teams" element={<TeamsView />} />
      </Routes>
    </>
  );
};
export const AuthenticationRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginView />} />
        <Route path="/login" element={<LoginView />} />
      </Routes>
    </>
  );
};
