import React, { useState } from "react";
import { Button, Modal, Tooltip, Input, Space } from "antd";
import { AuditOutlined, MessageOutlined, LockOutlined, SendOutlined } from "@ant-design/icons";
import OrderBatches from "components/batch-view";
import OrderCourses from "components/course-view";
import useServiceModal from "./useServiceModal";
const ServiceModal = ({ order_id, user_id, user_email, disabled }) => {
  const [modelOpen, setModalOpen] = useState(false);
  const { loading, inputError, handleSendEmail, updatePassword, resetPassowrdLoading, changeEmail, setChangeEmail, email, setEmail, updateLMSEmail, emailLoading } = useServiceModal();

  return (
    <>
      <Tooltip placement="bottom" title="Service">
        <Button
          size="medium"
          onClick={() => setModalOpen(true)}
          icon={<AuditOutlined />}
          disabled={disabled}
        />
      </Tooltip>
      <Modal
        title="Service"
        centered
        width={300}
        open={modelOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <div className="service-modal">
          <OrderCourses
            order_id={order_id}
            user_id={user_id}
            user_email={user_email}
            disabled={disabled}
          />
          <OrderBatches
            order_id={order_id}
            user_id={user_id}
            user_email={user_email}
          />
          <Button loading={loading} onClick={() => handleSendEmail(user_id, order_id)} size="large" block icon={<MessageOutlined />}>Send Enrollment Email</Button>
          <Button loading={resetPassowrdLoading} onClick={() => updatePassword(user_id)} size="large" block icon={<LockOutlined />}>Reset Password</Button>
          {
            changeEmail
              ?
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <Input status={inputError ? "error" : ""} value={email} onChange={(e) => setEmail(e.target.value)} defaultValue="Enter new email" />
                <Button onClick={() => updateLMSEmail(user_id)} loading={emailLoading} size="medium" type="primary">Save</Button>
              </Space.Compact>
              :
              <Button onClick={() => setChangeEmail(true)} size="large" block icon={<SendOutlined />}>Change Email</Button>
          }
        </div>
      </Modal>
    </>
  );
};
export default ServiceModal;