import { useState } from "react";
import { FileExcelOutlined } from "@ant-design/icons";
import { Button, Modal, App, Image } from "antd";
import { getLeadsReport } from "services";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const LeadsReport = () => {
    const [model, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { message } = App.useApp();
    const handleGenerateReport = async () => {
        setLoading(true);
        setModal(true);
        getLeadsReport().then((result) => {
            if (result.status === 1) {
                const worksheet = XLSX.utils.json_to_sheet(result.data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                saveAs(blob, "data.xlsx");
                message.open({ type: "success", content: "File Downloaded!", });
                setLoading(false);
                setModal(false);
            }
        })
    }
    return (
        <>
            <Button
                loading={loading}
                onClick={handleGenerateReport}
                size="large"
                block
                icon={<FileExcelOutlined />}>
                Yha Click krna Mana he
            </Button>
            <Modal
                title="Intezar Farmaiye"
                centered
                width={400}
                open={model}
                onCancel={setModal}
                footer={null}
            >
                <Image width={350} src="https://media.tenor.com/N96ReEUZ6EwAAAAM/shreyaghoshal.gif" />
            </Modal>
        </>
    )
}
export default LeadsReport;