import { Form, Input, Button, Space } from "antd";
import useProfileViewLogic from "business-logic/profile-view-logic";
const SecuritySettings = () => {
  const [form] = Form.useForm();
  const { onSubmitChangePassword } = useProfileViewLogic();
  return (
    <>
      <div className="d-flex justify-content-between">
        <Form
          form={form}
          layout="vertical"
          name="normal_login"
          className="login-form"
          onFinish={(values) =>
            onSubmitChangePassword(values, () => {
              form.resetFields();
            })
          }
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password style={{ minWidth: 400 }} />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password style={{ minWidth: 400 }} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Save
              </Button>
              <Button htmlType="reset">Reset</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default SecuritySettings;
