import { App } from "antd";
import { updatePayment, removeUserFromLms } from "services";
import useProfileViewLogic from "business-logic/profile-view-logic";
const usePaymentView = (order_id, lms_user_id, cb = () => {}) => {
  const { message } = App.useApp();
  const { userInfo } = useProfileViewLogic();
  const handleUpdatePayment = (value, key, payment_id) => {
    updatePayment({
      order_id: order_id,
      value: value,
      key: key,
      user_id: userInfo.id,
      payment_id: payment_id,
    }).then((result) => {
      if (result.status === 1) {
        if (key === "payment_status" && result.data < 25 && lms_user_id) {
          // Removing User from LMS and marking user generated as removed
          removeUserFromLms({ lms_user_id, order_id }).then((result) => {
            if (result.status === 1) {
              message.open({
                type: "success",
                content: result.message,
              });
            }
          });
        }
        message.open({
          type: "success",
          content: result.message,
        });
        cb();
      }
    });
  };
  return { handleUpdatePayment };
};
export default usePaymentView;
