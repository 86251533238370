import { getDashboard } from "services";
import { App } from "antd";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
const useDashboardViewLogic = () => {
  const { message } = App.useApp();
  const userInfo = useSelector((state) => state.user.userData);
  const { isLoading, isError, data } = useQuery(
    ["dashboard"],
    () => getDashboard(userInfo.id)
  );
  if (isError) {
    message.open({
      type: "error",
      content: "Something went wrong, please try again later!",
    });
  }

  return {
    isLoading,
    data,
  };
};
export default useDashboardViewLogic;
