import "./assets/css/stylesheet.scss";
import "./assets/fonts/cambria.ttf";
import LeftPanel from "components/left-bar";
import { AuthenticationRoutes, ApplicationRoutes } from "routes";
import { App,ConfigProvider } from "antd";
import { store } from "store";
import { Provider } from "react-redux";
import { useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { useCheckMobileScreen } from "hooks";
import ErrorBoundary from "components/error-boundary";
import Header from "components/header";
import { QueryClient, QueryClientProvider } from "react-query";
// We use QueryClient for managing and caching remote data in react query.
// first you need to create a new instance of Query client.
const queryClient = new QueryClient();
function Application() {
  let persistor = persistStore(store);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
    }, 3500);
  }
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            /* here is your global tokens */
          },
        }}
      >
        <App>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApplicationRoutesWrapper />
            </PersistGate>
          </Provider>
        </App>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

const ApplicationRoutesWrapper = () => {
  const isUserAuthenticated = useSelector((state) => state.user.isLoggedIn);
  const { isMobile } = useCheckMobileScreen();
  return isUserAuthenticated ? (
    <>
      <ErrorBoundary>
        {isMobile ? <Header /> : null}
        <main className="main">
          {!isMobile ? <LeftPanel /> : null}
          <section className="main-section">
            <ApplicationRoutes />
          </section>
        </main>
      </ErrorBoundary>
    </>
  ) : (
    <AuthenticationRoutes />
  );
};

export default Application;
