import { Typography, Spin, List, Avatar,Image } from "antd";
import useDashboardPlatform from "./useDashboardPlatform";
const { Title } = Typography;
const DashboardPaymentPlatforms = () => {
    const { loading } = useDashboardPlatform();
    return (
        <>
            <Spin spinning={loading} delay={500}>
                <div className="graph-card">
                    <Title level={4}>Payment Platforms</Title>
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={data}
                        bordered
                        renderItem={(item, index) => (
                            <List.Item
                                actions={[<a key="list-loadmore-edit">edit</a>]}
                            >
                                <List.Item.Meta
                                    avatar={<Image preview={false} width={60} src={item.image} />}
                                    title={item.title}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Spin>
        </>
    )
}
export default DashboardPaymentPlatforms;

const data = [
    {
        title: 'Payfort',
        image: "https://static.openfintech.io/payment_providers/payfort/logo.png?w=400&c=v0.59.26"
    },
    {
        title: 'Cashew',
        image: "https://ibsintelligence.com/wp-content/uploads/2022/08/Cashew-Payments.jpg"
    },
    {
        title: 'Tabby',
        image: "https://global-uploads.webflow.com/5d090629277f09e2a75aba38/615db905500fbe8e3e4006a1_tabby-logo-21622023082logo.png"
    },
    {
        title: 'Paypal',
        image: "https://logowik.com/content/uploads/images/paypal-new-20232814.logowik.com.webp"
    },
    {
        title: 'Fatoorah',
        image: "https://mobikul.com/wp-content/uploads/2019/03/myfatoorah-com.png"
    },
    {
        title: 'Razorpay',
        image: "https://upload.wikimedia.org/wikipedia/commons/8/89/Razorpay_logo.svg"
    },
    {
        title: 'Stripe',
        image: "https://eatfdnqxqwo.exactdn.com/wp-content/uploads/edd/2019/03/stripe-product-image.png"
    },
    {
        title: 'Tamara - Saudi',
        image: "https://www.fintechfutures.com/files/2022/08/683ec87cd067544e.jpg"
    },
    {
        title: 'Tamara - Master Account',
        image: "https://www.fintechfutures.com/files/2022/08/683ec87cd067544e.jpg"
    },
    {
        title: 'ADCB',
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLvN2s2aLREPJqFTPAY4tt6QV-PPK2O3spDRrrNArJ4Q&s"
    },
    {
        title: 'Yes Bank',
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Yes_Bank_Logo-01.png/2560px-Yes_Bank_Logo-01.png"
    },
    {
        title: 'Pakistan account',
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Pakistan.png/1024px-Flag_of_Pakistan.png"
    },

];
