import { axios } from "utils/axios";
const controller_name = "auth/";
const controller_name_lms_user = "lms-users/";

export const loginInUser = (data) => {
  let result = axios
    .post(`${controller_name}login`, data)
    .then((res) => {
      return res.data ? res.data : res;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const addUserToLms = (data) => {
  let result = axios
    .post(`${controller_name_lms_user}register`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const removeUserFromLms = (data) => {
  let result = axios
    .post(`${controller_name_lms_user}remove-user`, data)
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const sendCredientialsEmail = (params) => {
  let result = axios
    .get(`${controller_name_lms_user}send-email`, {
      params
    })
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};


export const updateLmsUser = (params) => {
  let result = axios
    .get(`${controller_name_lms_user}update-lms-user`, { params })
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};


