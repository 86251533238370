import { axios } from "utils/axios";
const controller_name = "courses/";
export const getAllCourses = () => {
  let result = axios
    .get(`${controller_name}get`)
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const saveEnrolledCourse = (data) => {
  let result = axios
    .post(`${controller_name}enroll-course`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const getUserEnrolledCourses = (data) => {
  let result = axios
    .post(`${controller_name}get-user-enrolled-courses`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};

export const removeUserEnrolledCourse = (enrolled_id) => {
  let result = axios
    .get(`${controller_name}remove-from-course/${enrolled_id}`)
    .then((res) => {
      return res?.data || [];
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};


export const saveCertificateAgainstCourse = (data) => {
  let result = axios
    .post(`${controller_name}generate-certificate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.request;
    });
  return result;
};