import { Statistic } from "antd";
const NewDashboardCard = ({ icon, value, title, isMobile, index }) => {
  return (
    <>
      <div className="dashboard-card">
        <div className={`icon-bar ${colors[index]}`}>{icon}</div>
        <div className="text-container">
          <Statistic
            valueStyle={{ fontSize: 25 }}
            value={value}
            title={title}
          />
        </div>
      </div>
    </>
  );
};
export default NewDashboardCard;

const colors = {
  0: "icon-bar-pink",
  1: "icon-bar-purple",
  5: "icon-bar-blue",
  3: "icon-bar-orange",
  2: "icon-bar-purple-dark",
  5: "#06B6D4",
  6: "#60A5FA",
  7: "#A78BFA",
};
