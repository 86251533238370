import { getOrderPaymentStatus } from "enums";
import { Tag } from "antd";
const OrderPaymentStatus = ({ record = {} }) => {
  return (
    <>
      <Tag
        style={{ height: "fit-content" }}
        color={
          getOrderPaymentStatus(record.sale_amount, record.payments)
            .percentPaid === 100
            ? "green"
            : "orange"
        }
      >
        {/* {`${
          getOrderPaymentStatus(record.sale_amount, record.payments)
            .totalPaidAmount
        } AED - (${getOrderPaymentStatus(
          record.sale_amount,
          record.payments
        ).percentPaid.toFixed(1)}%)`} */}
        {`${(record.sale_amount -
          getOrderPaymentStatus(record.sale_amount, record.payments)
            .totalPaidAmount).toFixed(2)
          } AED`}
      </Tag>
    </>
  );
};
export default OrderPaymentStatus;
