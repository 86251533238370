import {  Form, Input, Button, Avatar, Upload, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import useProfileViewLogic from "business-logic/profile-view-logic";
const GeneralSettings = () => {
  const { userInfo, changeAvatar } = useProfileViewLogic();
  const onSubmit = () => {};
  return (
    <>
      <div className="d-flex gap-1 mt-2 mb-2 align-items-center ">
        {userInfo.profile_image === null ? (
          <Avatar
            style={{
              backgroundColor: "red",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {userInfo?.f_name?.charAt(0)}
          </Avatar>
        ) : (
          <>
            <Avatar size="large" src={userInfo.profile_image}></Avatar>
          </>
        )}
        <Upload
          accept="image/*"
          showUploadList={false}
          beforeUpload={(file) => changeAvatar(file)}
          maxCount={1}
          name="profile_image"
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </div>
      <div className="d-flex justify-content-between">
        <Form
          layout="vertical"
          name="normal_login"
          className="login-form"
          initialValues={{
            f_name: userInfo.f_name,
            l_name: userInfo.l_name,
            profile_image: "",
          }}
          onFinish={onSubmit}
        >
          <Form.Item
            label="First Name"
            name="f_name"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input
              style={{ minWidth: 400 }}
              size="medium"
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="l_name"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input
              style={{ minWidth: 400 }}
              size="medium"
              placeholder="Last Name"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default GeneralSettings;
