import useDashboardViewLogic from "business-logic/dashboard-logic";
// import DashboardCard from "components/dashboard-card";
import NewDashboardCard from "components/new-dashboard-card";
import { useCheckMobileScreen } from "hooks";
import {
  // ContainerOutlined,
  DollarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import AdminLineChartGraph from "components/dashboard-graph/admin-graphs";
import DashboardPaymentPlatforms from "components/dashboard-payment-platform";
const DashboardView = () => {
  const { isMobile } = useCheckMobileScreen();
  const { isLoading, data } = useDashboardViewLogic();
  return (
    <>
      <Spin spinning={isLoading} delay={500}>
        <div className="dashboard-container">
          <NewDashboardCard
            isMobile={isMobile}
            value={data?.data?.totalOrders || 0}
            icon={<UserOutlined color="white" />}
            title="Registered Users"
            index={0}
          />
          <NewDashboardCard
            isMobile={isMobile}
            value={`${data?.data?.totalPaymentsAmount +
              data?.data?.totalRefundedAmount || 0
              } AED`}
            icon={<DollarOutlined color="white" />}
            title="Total Revenue"
            index={1}
          />
          <NewDashboardCard
            isMobile={isMobile}
            value={`${data?.data?.totalPaymentsAmount || 0} AED`}
            icon={<DollarOutlined color="white" />}
            title="Total cash"
            index={2}
          />

          <NewDashboardCard
            isMobile={isMobile}
            value={data?.data?.totalRefundedAmount || 0}
            icon={<DollarOutlined color="white" />}
            title="Total refunded"
            index={3}
          />
          {/* 
          <DashboardCard
            isMobile={isMobile}
            title="Total no of clients"
            icon={<ContainerOutlined />}
            value={data?.data?.totalOrders || 0}
          />
          <DashboardCard
            isMobile={isMobile}
            index={1}
            icon={<DollarOutlined />}
            title="Total Revenue"
            value={`${
              data?.data?.totalPaymentsAmount +
                data?.data?.totalRefundedAmount || 0
            } AED`}
          />
          <DashboardCard
            isMobile={isMobile}
            index={2}
            icon={<DollarOutlined />}
            title="Total cash"
            value={`${data?.data?.totalPaymentsAmount || 0} AED`}
          />
          <DashboardCard
            isMobile={isMobile}
            index={3}
            icon={<DollarOutlined />}
            title="Total refunded"
            value={data?.data?.totalRefundedAmount || 0}
          /> */}
          {/* <DashboardCard
            isMobile={isMobile}
            index={2}
            icon={<FileSearchOutlined />}
            title="Total transactions"
            value={data?.data?.totalPayments || 0}
          /> */}
        </div>
      </Spin>
      <div className="d-flex gap-1">
        <AdminLineChartGraph />
        {/* <DashboardPaymentPlatforms /> */}
      </div>

    </>
  );
};
export default DashboardView;
