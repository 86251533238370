import { useState } from "react";
import { Button, Modal, Input, Space, Upload, Typography, App, Image, Select } from "antd";
import { FileExcelOutlined, UploadOutlined, AliwangwangOutlined } from "@ant-design/icons";
import { updateLeadCosts } from "services";
const { Text } = Typography;
const { Option } = Select;
const UpdateLeadCostModal = () => {
    const { message } = App.useApp();
    const [modelOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [campaginNo, setCampaignNo] = useState("");
    const [currency, setCurrency] = useState("AED");
    const [file, setFile] = useState(null);
    const [result, setResult] = useState(false);
    const handleFile = (record) => {
        const { file } = record;
        setFile(file);
    }
    const closeModal = () => {
        setCampaignNo("");
        setFile(null);
        setModalOpen(false);
        setResult(false);
    }

    const uploadFileCall = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', file);
        const params = {
            campaign: campaginNo,
            currency
        };
        updateLeadCosts(params, formData).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: result.message,
                });
                setResult(result.data);
            }
            setLoading(false);
        })
    }
    return (
        <>
            <Button onClick={() => setModalOpen(true)} size="large" block icon={<FileExcelOutlined />}>Update Lead Cost</Button>
            <Modal
                title="Update Lead Costs"
                centered
                width={400}
                open={modelOpen}
                onCancel={closeModal}
                footer={null}
            >
                <div className="service-modal">
                    {
                        result
                            ?
                            <Image
                                width={350}
                                src={result}
                            />
                            :
                            <Space size="middle" direction="vertical">
                                <Input value={campaginNo} onChange={(e) => setCampaignNo(e.target.value)} placeholder="Enter campagin #" />
                                <Select
                                    defaultValue="AED"
                                    onChange={(value) => setCurrency(value)}
                                    value={currency}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <Option value="AED">AED</Option>
                                    <Option value="PKR">PKR</Option>
                                    <Option value="INR">INR</Option>
                                    <Option value="USD">USD</Option>
                                </Select>
                                {
                                    file
                                        ?
                                        <>
                                            <Text code>{file.name}</Text>
                                            <Button disabled={campaginNo === ""} loading={loading} onClick={uploadFileCall} type="primary" block icon={<AliwangwangOutlined />}>Ittu Bittu Jim Batuta</Button>
                                        </>
                                        :
                                        <Upload showUploadList={false} customRequest={(file) => handleFile(file)} name="file" >
                                            <Button block icon={<UploadOutlined />}>Attach Excel Sheet</Button>
                                        </Upload>
                                }
                            </Space>
                    }
                </div>
            </Modal>
        </>
    )
}
export default UpdateLeadCostModal;